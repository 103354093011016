import React from "react"
import Slider from "react-slick"
import "../assets/css/slick.css"

export default function WorkSlider({ data }) {
    const settings = {
        lazyLoad: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                centerPadding: '80px'
            }
        }]
    };

    return <Slider {...settings}>
        {data.map(({ title, img, text_left, text_right }, index) => {
            return (
                <div key={index}>
                    <div className="slider-image">
                        <img width="700" height="378" alt="title" src={img} />
                    </div>
                    <div className="slider-description">
                        <h3>{title}</h3>
                        <div className="flex flex-col md:flex-row justify-between">
                            <span dangerouslySetInnerHTML={{__html: text_left}}></span>
                            <span dangerouslySetInnerHTML={{__html: text_right}}></span>
                        </div>
                    </div>
                </div>
            );
        })}
    </Slider>
}