import React from "react"
import WorkSlider from "../components/workSlider"

export default function RecentWork({ title='Recent Work', className='', type=['website', 'ecommerce', 'app'] }) {
    const portfolio = [
        {
            title: 'AMOIL',
            img: '/static/img/portfolio/cropped/amoil.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Magnetic Creative',
            category: 'website'
        },{
            title: 'CICS ltd.',
            img: '/static/img/portfolio/cropped/cics-ltd.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'website'
        },{
            title: 'CoronaViewer',
            img: '/static/img/portfolio/cropped/corona-viewer.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'app'
        },{
            title: 'G4S Deposita',
            img: '/static/img/portfolio/cropped/deposita.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Magnetic Creative',
            category: 'website'
        },{
            title: 'HIIT Macros',
            img: '/static/img/portfolio/cropped/hiit-macros.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Brandpride Creative',
            category: 'app'
        },{
            title: 'Megan de Beyer',
            img: '/static/img/portfolio/cropped/megan-de-beyer.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'website'
        },{
            title: 'MyPocketCoach',
            img: '/static/img/portfolio/cropped/my-pocket-coach.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Brandpride Creative',
            category: 'app'
        },{
            title: 'Sir Fresh',
            img: '/static/img/portfolio/cropped/sir-fresh.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Andre de Waal',
            category: 'ecommerce'
        },{
            title: 'Valiant Check-In',
            img: '/static/img/portfolio/cropped/valiant-checkin.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'app'
        },{
            title: 'Lieben Wines & Spirits',
            img: '/static/img/portfolio/cropped/lieben-wine.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>simplifi',
            category: 'ecommerce'
        },{
            title: 'The Baking Tin',
            img: '/static/img/portfolio/cropped/the-baking-tin.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'ecommerce'
        },{
            title: 'the SPACE.',
            img: '/static/img/portfolio/cropped/enjoy-the-space.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'website'
        },{
            title: 'Flexion Mobile',
            img: '/static/img/portfolio/cropped/flexion-mobile.jpg',
            text_left: '<strong>Role: </strong>creative &amp; programming',
            category: 'website'
        },{
            title: 'Sir Fruit',
            img: '/static/img/portfolio/cropped/sir-fruit.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Bain &amp; Bunkell',
            category: 'website'
        },{
            title: 'PixelFaerie',
            img: '/static/img/portfolio/cropped/pixelfaerie.jpg',
            text_left: '<strong>Role: </strong>programming',
            text_right: '<strong>Creative: </strong>Sascha Rupert',
            category: 'website'
        }
    ];

    let data = portfolio.filter(obj => type.includes(obj.category));

    return (
        <div id="examples" className={`component double-padding-bottom ${className}`}>
            <h2 className="text-center">{title}<span className="symbol symbol-blue"><span></span></span></h2>
            <WorkSlider
                data={data}
            />
        </div>
    )
}